import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { AlertController,LoadingController,ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
    public plt: Platform,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public toastController: ToastController

  ) { }

  is_app() {
    if (this.plt.is('cordova')) {
      if (this.plt.is('ios')) {
        return 'ios';
      } else {
        return 'android';
      }
    } else {
      return false;
    }
  }

  async presentAlert(message, header = '', subHeader = '') {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  loading;
  async presentLoading() {
    this.loading = await this.loadingController.create();

    //const { role, data } = await loading.onDidDismiss();
    await this.loading.present();

  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  unserialize (data) {
    var that = this,
      utf8Overhead = function (chr) {
        // http://phpjs.org/functions/unserialize:571#comment_95906
        var code = chr.charCodeAt(0);
        if (code < 0x0080) {
          return 0;
        }
        if (code < 0x0800) {
          return 1;
        }
        return 2;
      },
      error = function (type, msg, filename, line) {
        throw new window[type](msg, filename, line);
      },
      read_until = function (data, offset, stopchr) {
        var i = 2, buf = [], chr = data.slice(offset, offset + 1);
  
        while (chr != stopchr) {
          if ((i + offset) > data.length) {
            console.log('Error', 'Invalid');
          }
          buf.push(chr);
          chr = data.slice(offset + (i - 1), offset + i);
          i += 1;
        }
        return [buf.length, buf.join('')];
      },
      read_chrs = function (data, offset, length) {
        var i, chr, buf;
  
        buf = [];
        for (i = 0; i < length; i++) {
          chr = data.slice(offset + (i - 1), offset + i);
          buf.push(chr);
          length -= utf8Overhead(chr);
        }
        return [buf.length, buf.join('')];
      },
      _unserialize = function (data, offset) {
        var dtype, dataoffset, keyandchrs, keys,
          readdata, readData, ccount, stringlength,
          i, key, kprops, kchrs, vprops, vchrs, value,
          chrs = 0,
          typeconvert = function (x) {
            return x;
          };
  
        if (!offset) {
          offset = 0;
        }
        dtype = (data.slice(offset, offset + 1)).toLowerCase();
  
        dataoffset = offset + 2;
  
        switch (dtype) {
          case 'i':
            typeconvert = function (x) {
              return parseInt(x, 10);
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'b':
            typeconvert = function (x) {
              return parseInt(x, 10) !== 0;
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'd':
            typeconvert = function (x) {
              return parseFloat(x);
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'n':
            readdata = null;
            break;
          case 's':
            ccount = read_until(data, dataoffset, ':');
            chrs = ccount[0];
            stringlength = ccount[1];
            dataoffset += chrs + 2;
  
            readData = read_chrs(data, dataoffset + 1, parseInt(stringlength, 10));
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 2;
            if (chrs != parseInt(stringlength, 10) && chrs != readdata.length) {
              console.log('SyntaxError', 'String length mismatch');
            }
            break;
          case 'a':
            readdata = {};
  
            keyandchrs = read_until(data, dataoffset, ':');
            chrs = keyandchrs[0];
            keys = keyandchrs[1];
            dataoffset += chrs + 2;
  
            for (i = 0; i < parseInt(keys, 10); i++) {
              kprops = _unserialize(data, dataoffset);
              kchrs = kprops[1];
              key = kprops[2];
              dataoffset += kchrs;
  
              vprops = _unserialize(data, dataoffset);
              vchrs = vprops[1];
              value = vprops[2];
              dataoffset += vchrs;
  
              readdata[key] = value;
            }
  
            dataoffset += 1;
            break;
          default:
          console.log('SyntaxError', 'Unknown / Unhandled data type(s): ' + dtype);
            break;
        }
        return [dtype, dataoffset - offset, typeconvert(readdata)];
      }
    ;
  
    return _unserialize((data + ''), 0)[2];
  }
}